import { root_props } from '../main';

export function has_transsion_board() {
    return ['nick.wang@bidderdesk.com', 'li.tian@bidderdesk.com', 'john@bidderdesk.com'].includes(root_props.user.email)
}

export function is_manager() {
    return ['nick.wang@bidderdesk.com', 'li.tian@bidderdesk.com', 'john@bidderdesk.com',
        'vincent.gao@bidderdesk.com', 'lv@bidderdesk.com', 'celine@bidderdesk.com', 'tony.zhang@bidderdesk.com'].includes(root_props.user.email)
}

export function is_mi() {
    return ['mi.op@bidderdesk.com'].includes(root_props.user.email)
}