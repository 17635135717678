<template>
    <aside-nav ref="nav">
        <aside-group title="产品数据">
            <aside-item index="PMDashboard" icon="DataAnalysis" title="默认看板"></aside-item>
            <aside-menu v-if="showTranssion()" title="传音看板" icon="UserFilled" index="TGuide">
              <aside-sub-menu title="数据总览" icon="DataAnalysis" index="TDashboard"></aside-sub-menu>
              <aside-sub-menu title="用户增长" icon="DataAnalysis" index="TUserGrowth"></aside-sub-menu>
            </aside-menu>
            <aside-menu v-if="!is_mi()" title="获客看板" icon="UserFilled" index="UAGuide">
                <aside-sub-menu title="投放总览" icon="DataAnalysis" index="UADashboard"></aside-sub-menu>
                <aside-sub-menu title="Facebook" icon="Flag" index="UAFacebookDashboard"></aside-sub-menu>
                <aside-sub-menu title="GoogleAds" icon="Flag" index="UAGoogleAdsDashboard"></aside-sub-menu>
                <aside-sub-menu title="用户增长" icon="DataAnalysis" index="UGDashboard"></aside-sub-menu>
            </aside-menu>
            <aside-menu v-if="!is_mi()" title="收入看板" icon="Money" index="RevenueGuide">
                <aside-sub-menu title="广告总览" icon="DataAnalysis" index="IAADashboard"></aside-sub-menu>
                <aside-sub-menu title="广告位" icon="FullScreen" index="IAAPlacement"></aside-sub-menu>
                <aside-sub-menu title="订阅留存" icon="Magnet" index="IAPSubscription"></aside-sub-menu>
                <aside-sub-menu title="内购订单" icon="Goods" index="IAPOrder"></aside-sub-menu>
            </aside-menu>
            <growth-guide index="PMGrowthDashboard" :loading="growth.loading" :data="growth.data"></growth-guide>
        </aside-group>
        <aside-group v-if="!is_mi()" title="分析工具">
            <aside-item title="AB测" index="ABTest" icon="Setting"></aside-item>
            <aside-item title="留存报告" index="PMRetention" icon="Magnet"></aside-item>
            <aside-item title="漏斗模型" index="PMFunnel" icon="Filter"></aside-item>
            <aside-item title="时长分析" index="Engagement" icon="Clock"></aside-item>
            <aside-item title="指标分析" index="MetricAnalysis" icon="DataLine"></aside-item>
            <aside-item title="LT预测" index="LTPredict" icon="User"></aside-item>
        </aside-group>
        <aside-group v-if="!is_mi()" title="工作流程">
            <aside-item title="消息推送" index="Push" icon="Message"></aside-item>
            <aside-item title="产品档案" index="ArchiveIndex" icon="FolderChecked"></aside-item>
            <aside-item title="埋点管理" index="AnchorGuide" icon="AddLocation"></aside-item>
            <aside-item title="指标管理" index="DWMetric" icon="Guide"></aside-item>
        </aside-group>
    </aside-nav>
    <el-main style="padding: 0">
        <h3 style="margin: 16px 30px" v-if="hasHeader">{{ title }}</h3>
        <el-divider style="margin: 8px 0" v-if="hasHeader"></el-divider>
        <el-scrollbar class="main" :style="{height: hasHeader?'calc(100% - 69px)':'100%'}">
            <router-view></router-view>
        </el-scrollbar>
    </el-main>
</template>

<script>
import AsideNav from "../../base/AsideNav";
import AsideGroup from "../../base/AsideGroup";
import AsideItem from "../../base/AsideItem";
import AsideMenu from "../../base/AsideMenu";
import axios from 'ts-axios-new';
import GrowthGuide from "./GrowthGuide";
import {getLocalObj} from "../../libs/storage";
import {update} from "../../libs/utils";
import AsideWrapper from "../../base/AsideWrapper";
import AsideSubMenu from "../../base/AsideSubMenu";
import {has_transsion_board, is_mi} from "../../libs/permission";

export default {
    name: "PMGuide",
    components: {AsideSubMenu, AsideWrapper, GrowthGuide, AsideNav, AsideMenu, AsideItem, AsideGroup},
    provide() {
        return {
            growth: this.growth, event_list: this.event_list,
        }
    },
    data() {
        const growth = {label: 'growth-guide', index: 'PMGrowthDashboard', loading: false, data: []};
        return {
            growth, title: document.title, hasHeader: true, event_list: [],
            no_header: ['SqlIndex', 'PMDashboard', 'UADashboard', 'IAADashboard', 'IAASdk', 'IAAPlacement', 'Waterfall'],
        }
    },
    methods: {
      is_mi,
        init() {
            this.growth.loading = true;
            axios.get(`/api/v1/growth/dashboard`, {params: {product_id: this.$route.params.productId}}).then(res => {
                this.growth.data = res.data.data.dashboardList;
                const sort = getLocalObj(`/growthGuide/sort/${this.$route.params.productId}`);
                if (sort)
                    this.growth.data.sort(function (a, b) {
                        return (sort.indexOf(a.id) < 0 ? 99 : sort.indexOf(a.id)) - (sort.indexOf(b.id) < 0 ? 99 : sort.indexOf(b.id));
                    });
                this.growth.loading = false;
                if (this.$route.name === 'PMGrowthDashboard') {
                    if (this.growth.data.length) {
                        let exists = false;
                        this.growth.data.forEach(g => {
                            if (this.$route.query.dashboardId === g.id) {
                                exists = true;
                            }
                        });
                        if (!exists) {
                            this.$router.push({query: {dashboardId: this.growth.data[0].id}});
                        }
                    } else {
                        this.$router.push({name: 'PMDashboard'})
                    }
                }
            });
            axios.get(`/api/v1/event`, {params: {product_id: this.$route.params.productId}}).then(res => {
                update(this.event_list, res.data.data.eventList);
            });
        },
        showTranssion() {
          const productId = this.$route.params.productId
          return has_transsion_board() && ["6620e76589782d48cdd3f0cc", "63fd63518150a34fe99b4670"].includes(productId)
        },
    },
    mounted() {
        this.hasHeader = this.no_header.indexOf(this.$route.name) < 0;
        this.init();
        if (this.$route.name === 'PMGuide') {
            // 自动进入第一个导航
            this.$router.push({name: 'PMDashboard'})
        }
    },
    watch: {
        "$route.params.productId"() {
            if (this.$route.params.productId) {
                this.init();
            }
        },
        "$route.name"() {
            this.hasHeader = this.no_header.indexOf(this.$route.name) < 0;
            this.title = document.title;
        },
        // "$route.query.dashboardId"() {
        //     this.init();
        // },
        collapse(val) {
            if (val) {
                this.$refs.growthMenu.collapse = val;
            }
        },
    }
}
</script>

<style scoped>
</style>